import { asBlob } from 'html-docx-js-typescript';
import { saveAs } from 'file-saver';
import { FILE_VALIDATION_ERRORS, NORMALIZING_ARABIC_CHARS_MAPPING } from '@/enums';
import html2pdf from 'html2pdf.js';

export default class Utils {
  static MAX_FILE_SIZE = 5 * 1024 * 1024;

  static XML_FILE_type = 'text/xml';

  static TXT_FILE_type = 'text/plain';

  static DOC_FILE_types = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
  ];

  static EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  static DAY_IN_MILLISECONDS = 1 * 24 * 60 * 60 * 1000;

  static FILE_SIZE_VALIDATION(file: File) {
    if (!file) {
      return false;
    }
    if ((file.size as number) > Utils.MAX_FILE_SIZE) {
      return false;
    }
    return true;
  }

  static VALIDATE_TXT_FILE(file: File): { valid: boolean; type?: string } {
    if (!file) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.TYPE };
    }
    if (file.type !== Utils.TXT_FILE_type) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.TYPE };
    }
    if (!Utils.FILE_SIZE_VALIDATION(file)) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.SIZE };
    }
    return { valid: true };
  }

  static VALIDATE_XML_FILE(file: File): { valid: boolean; type?: string } {
    if (!file) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.TYPE };
    }
    if (file.type !== Utils.XML_FILE_type) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.TYPE };
    }
    if (!Utils.FILE_SIZE_VALIDATION(file)) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.SIZE };
    }
    return { valid: true };
  }

  static VALIDATE_DOC_FILE(file: File): { valid: boolean; type?: string } {
    if (!file) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.TYPE };
    }
    if (!Utils.DOC_FILE_types.includes(file.type)) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.TYPE };
    }
    if (!Utils.FILE_SIZE_VALIDATION(file)) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.SIZE };
    }
    return { valid: true };
  }

  static VALIDATE_XLSX_FILE(file: File): { valid: boolean; type?: string } {
    if (!file) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.TYPE };
    }
    if (file.type !== Utils.EXCEL_FILE_TYPE) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.TYPE };
    }
    if (!Utils.FILE_SIZE_VALIDATION(file)) {
      return { valid: false, type: FILE_VALIDATION_ERRORS.SIZE };
    }
    return { valid: true };
  }

  static PRINT_REPORT(printElmHtml, lang, customStyles = '') {
    const printWindow = window.open('', 'PRINT', 'width=800,height=900');
    const oldHead = document.head.innerHTML;

    if (printWindow) {
      printWindow.document.write(`
        <!DOCTYPE html>
        <html dir="${lang === 'ar' ? 'RTL' : 'LTR'}">
          <head>
          ${oldHead}

            <style>
              h3 { text-align: center; }
              h3 .d-flex { display: flex; }
              h3 .justify-center { justify-content: center; }
              h3 .mx-2 { margin: 5px 10px; }
              table { page-break-inside: always; }
              table, th, td {
                border: 1px solid black;
                text-align: center;
                background-color: white !important;
              }
              th {
                font-weight: bold;
                line-height: 34px;
              }
              table th span {
                color: black !important;
              }
              th, td { padding: 6px; }
              ${customStyles}
            </style>
          </head>
          <body onload="executePrint();">
            ${printElmHtml}

            <script>
              function executePrint(){
                window.print();
                setTimeout(() => { window.close(); });
              }
            </script>
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.focus();
    }
  }

  static DOWNLOAD_EXAM_PDF(printElmHtml, paperDirection, examLangCode, modelNo, examTitle) {
    const oldHead = document.head.innerHTML;
    const html = `
      <html
        dir="${paperDirection}"
        lang="${examLangCode}"
        xml:lang="${examLangCode}"
      >
        <head>
          ${oldHead}
        </head>
        <body>
          <div
            dir="${paperDirection}"
          >
            ${printElmHtml}
          </div>
        </body>
      </html>
    `;

    html2pdf(html, {
      margin: 0.5,
      filename: `${examTitle} - Model ${modelNo}.pdf`,
      image: { type: 'jpg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'legacy', 'css'] },
    });
  }

  static PRINT_EXAM(printElmHtml, paperDirection, examLangCode) {
    const printWindow = window.open('', 'PRINT', 'width=800,height=900');
    const oldHead = document.head.innerHTML;

    if (printWindow) {
      printWindow.document.write(`
      <!doctype html>
        <html

        dir='${paperDirection}'
        lang='${examLangCode}'
        xml:lang="${examLangCode}"
        >

        <head>
        ${oldHead}
        </head>
        <body >
                  ${printElmHtml}
                  <script>
                  document.addEventListener( "DOMContentLoaded", function() {
                  setTimeout(function(){


                    window.print();
                setTimeout(() => { window.close(); });
                  },1000);

                    }, false );
            </script>
        </body>
        </html>


    `);
      printWindow.document.close();
      printWindow.focus();
    }
  }

  static EXPORT_EXAM(printElmHtml, paperDirection, examLangCode, examTitle) {
    const oldHead = document.head.innerHTML;

    const htmlToExport = `
        <!DOCTYPE html>
        <html
          dir='${paperDirection}'
          lang='${examLangCode}'
          xml:lang="${examLangCode}"
          xmlns:o='urn:schemas-microsoft-com:office:office'
          xmlns:w='urn:schemas-microsoft-com:office:word'
          xmlns='http://www.w3.org/TR/REC-html40'
        >
          <head>
          ${oldHead}
            <meta charset="UTF-8" />
            <title> ${examTitle || 'exam'} </title>
          </head>
          <body>
            ${printElmHtml}
          </body>
        </html>
      `;
    asBlob(htmlToExport).then((data) => {
      saveAs(data, `${examTitle || 'exam'}.docx`); // save as docx file
    });
  }

  static CONVERT_FROM_LOCAL_DATE_TO_ISO_STRING_DATE(date, addDayToDate = false) {
    if (!(date && !Number.isNaN(Date.parse(date)))) {
      return '';
    }
    let currentDate = new Date(date);
    if (addDayToDate) {
      currentDate = new Date(currentDate.getTime() + Utils.DAY_IN_MILLISECONDS);
    }
    return new Date(currentDate.toLocaleDateString()).toISOString();
  }

  static SUBTRACT_TIME_HOURS(numOfHours, date = new Date()) {
    date.setHours(date.getHours() - numOfHours);

    return date;
  }

  static CONVERT_FROM_EG_DATE_TO_ISO_DATE(date) {
    if (!(date && !Number.isNaN(Date.parse(date)))) {
      return '';
    }
    let currentDate = new Date(date);
    currentDate = Utils.SUBTRACT_TIME_HOURS(2, currentDate);
    const YYYY = currentDate.getFullYear();
    let MM = String(currentDate.getMonth() + 1); // January is 0!
    let DD = String(currentDate.getDate());
    let hh = String(currentDate.getHours());
    let mm = String(currentDate.getMinutes());
    let ss = String(currentDate.getSeconds());
    let ms = String(currentDate.getMilliseconds());
    if (Number(MM) < 10) {
      MM = `0${MM}`;
    }
    if (Number(DD) < 10) {
      DD = `0${DD}`;
    }
    if (Number(hh) < 10) {
      hh = `0${hh}`;
    }
    if (Number(mm) < 10) {
      mm = `0${mm}`;
    }
    if (Number(ss) < 10) {
      ss = `0${ss}`;
    }
    if (Number(ms) < 10) {
      ms = `00${ms}`;
    } else if (Number(ms) < 100) {
      ms = `0${ms}`;
    }
    return `${YYYY}-${MM}-${DD}T${hh}:${mm}:${ss}.${ms}Z`;
  }

  static padNumber(number: string | number): string {
    if (number < 10) {
      return `0${number}`;
    }
    return `${number}`;
  }

  static getStringTime(totalTime: string | number): string {
    const splittedTime = totalTime.toString().split('.');
    const hours = Math.floor(parseInt(splittedTime[0], 10) / 60);
    const minutes = Math.floor(parseInt(splittedTime[0], 10) - hours * 60);
    const seconds = Math.round(parseFloat(`0.${splittedTime[1] || 0}`) * 60);
    return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }

  static normalizeString(text: string): string {
    if (typeof text !== 'string') return text;
    return text
      .replace(
        new RegExp(Object.keys(NORMALIZING_ARABIC_CHARS_MAPPING).join('|'), 'g'),
        (match) => NORMALIZING_ARABIC_CHARS_MAPPING[`${match}`] || match,
      )
      .toLowerCase();
  }
}
